/**
 * @license
 * MyFonts Webfont Build ID 3793491, 2019-08-05T11:41:11-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: TradeGothicLTPro-Cn18 by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/trade-gothic/pro-condensed-18/
 * 
 * Webfont: TradeGothicLTPro-Cn18Obl by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/trade-gothic/pro-condensed-18-oblique/
 * 
 * Webfont: TradeGothicLTPro-BdCn20 by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/trade-gothic/pro-bold-condensed-20/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3793491
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright 2014 Monotype GmbH. All rights reserved.
 * 
 * © 2019 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/39e253");

  
@font-face {font-family: 'TradeGothicLTPro-Cn18';src: url('https://cdn.shopify.com/s/files/1/0386/3193/t/9/assets/39E253_0_0.eot');src: url('https://cdn.shopify.com/s/files/1/0386/3193/t/9/assets/39E253_0_0.eot?#iefix') format('embedded-opentype'),url('https://cdn.shopify.com/s/files/1/0386/3193/t/9/assets/39E253_0_0.woff2') format('woff2'),url('https://cdn.shopify.com/s/files/1/0386/3193/t/9/assets/39E253_0_0.woff') format('woff'),url('https://cdn.shopify.com/s/files/1/0386/3193/t/9/assets/39E253_0_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TradeGothicLTPro-Cn18Obl';src: url('https://cdn.shopify.com/s/files/1/0386/3193/t/9/assets/39E253_1_0.eot');src: url('https://cdn.shopify.com/s/files/1/0386/3193/t/9/assets/39E253_1_0.eot?#iefix') format('embedded-opentype'),url('https://cdn.shopify.com/s/files/1/0386/3193/t/9/assets/39E253_1_0.woff2') format('woff2'),url('https://cdn.shopify.com/s/files/1/0386/3193/t/9/assets/39E253_1_0.woff') format('woff'),url('https://cdn.shopify.com/s/files/1/0386/3193/t/9/assets/39E253_1_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TradeGothicLTPro-BdCn20';src: url('https://cdn.shopify.com/s/files/1/0386/3193/t/9/assets/39E253_2_0.eot');src: url('https://cdn.shopify.com/s/files/1/0386/3193/t/9/assets/39E253_2_0.eot?#iefix') format('embedded-opentype'),url('https://cdn.shopify.com/s/files/1/0386/3193/t/9/assets/39E253_2_0.woff2') format('woff2'),url('https://cdn.shopify.com/s/files/1/0386/3193/t/9/assets/39E253_2_0.woff') format('woff'),url('https://cdn.shopify.com/s/files/1/0386/3193/t/9/assets/39E253_2_0.ttf') format('truetype');}

.TradeGothicLTPro-Cn18 { 
	font-family: TradeGothicLTPro-Cn18;
	font-weight: normal;
	font-style: normal;
}
.TradeGothicLTPro-Cn18Obl { 
	font-family: TradeGothicLTPro-Cn18Obl;
	font-weight: normal;
	font-style: normal;
}
.TradeGothicLTPro-BdCn20 { 
	font-family: TradeGothicLTPro-BdCn20;
	font-weight: normal;
	font-style: normal;
}